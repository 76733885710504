import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [16,[2],[3]],
		"/(pages)/about": [33,[],[14]],
		"/(app)/add-ons": [17,[2,4],[3,5]],
		"/(app)/add-ons/runs": [20,[2,4],[3,5]],
		"/(app)/add-ons/[owner]/[repo]": [18,[2,4],[3,5,6]],
		"/(app)/add-ons/[owner]/[repo]/[event]": [19,[2,4],[3,5,6]],
		"/(app)/app": [21,[2],[3]],
		"/(app)/documents": [~22,[2,7],[3,8]],
		"/(app)/documents/[id]-[slug].[format]": [28,[2,7],[3,8]],
		"/(app)/documents/[id]-[slug]": [~25,[2,7],[3,8,9]],
		"/(app)/documents/[id]-[slug]/annotations/[note_id]": [26,[2,7],[3,8,9]],
		"/(app)/documents/[id]-[slug]/pages/[page]": [27,[2,7],[3,8,9]],
		"/(app)/documents/[id]/annotations/[note_id]": [23,[2,7],[3,8]],
		"/(app)/documents/[id]/pages/[page]": [24,[2,7],[3,8]],
		"/embed/documents/[id]-[slug]": [38,[15]],
		"/embed/documents/[id]/annotations/[note_id]": [36,[15]],
		"/embed/documents/[id]/pages/[page]": [37,[15]],
		"/embed/projects/[project_id]-[slug]": [39,[15]],
		"/(pages)/help/[...path]": [34,[],[14]],
		"/(pages)/home": [35,[],[14]],
		"/(app)/projects": [~29,[2,10],[3,11]],
		"/(app)/projects/[id]-[slug]": [31,[2,10],[3,11,12]],
		"/(app)/projects/[id]": [30,[2,10],[3,11]],
		"/(app)/upload": [32,[2],[3,13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';